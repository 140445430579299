import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";

import { Home } from "../pages/home";
import { Profile } from "../pages/profile";

export const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/profile/:id" element={<Profile />} />
    </Routes>
  </BrowserRouter>
);
