import "./index.css"

export const RadialBar = ({
  size = 100,
  strokeWidth = 5,
  percentage = 25,
  colors = [
    [0, 39, "#db2360", "#571435"],
    [40, 69, "#d2d531", "#423d0f"],
    [70, 100, "#21d07a", "#204529"]
  ],
}: {
  size?: number,
  strokeWidth?: number,
  percentage?: number,
  colors?: [number, number, string, string?][]
}) => {
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (size - strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${size} ${size}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - dashArray * percentage / 100;

  return (
    <svg
      className="bar-wrapper"
      width={size}
      height={size}
      viewBox={viewBox}
    >
      <circle
        className="circle-track"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={colors.reduce((color: string, option: [number, number, string, string?]) => {
          if (option[3] && percentage >= option[0] && percentage <= option[1]) {
            return option[3];
          }
          return color;
        }, "#ddd")}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className="circle-progress"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset
        }}
        stroke={colors.reduce((color: string, option: [number, number, string, string?]) => {
          if (percentage >= option[0] && percentage <= option[1]) {
            return option[2];
          }
          return color;
        }, "#ff0000")}
      />
      <text
        className="percent-text"
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        fontSize={size / 2.7}
      >
        {percentage}
        <tspan className="percent-symbol">%</tspan>
      </text>
    </svg>
  );
};
