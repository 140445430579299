import { useParams } from "react-router-dom";
import { ProfileComponent } from "../components/Profile";

export const Profile: React.FC = (): JSX.Element => {
  const params = useParams();

  return (
    <ProfileComponent id={String(params.id)} />
  );
};
