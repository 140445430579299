import {
  gql,
  NetworkStatus,
  useQuery
} from "@apollo/client";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";

import { client } from "../../../services/client";
import { SearchMovies, SearchMovies_movies_edges } from "../../../types";

import "./list.css"

const SEARCH_MOVIES = gql`
  query SearchMovies ($search: String, $page: Int) {
    movies(search: $search, page: $page) {
      totalCount
      edges {
        node {
          imdbID
          Title
          Poster
          Year
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export function List({ searchInput }: { searchInput: string }) {
  const { data, networkStatus, error, fetchMore } = useQuery(
    SEARCH_MOVIES,
    {
      client,
      notifyOnNetworkStatusChange: true,
      variables: {
        search: searchInput
      }
    }
  );

  if (networkStatus === NetworkStatus.loading) {
    return <div>Loading ...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <>
      <i>Nombre de resultats : {data.movies.totalCount}</i>
      <InfiniteScroll
        pageStart={0}
        hasMore={data.movies.pageInfo.hasNextPage}
        loader={<div className="loader" key={0}>Loading ...</div>}
        loadMore={(page) => fetchMore({
          variables: { page: page + 1 },
          updateQuery: (prev: SearchMovies, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;

            return Object.assign({}, prev, {
              movies: {
                totalCount: fetchMoreResult.movies && fetchMoreResult.movies.totalCount,
                edges: [...((prev.movies && prev.movies.edges) || []), ...((fetchMoreResult.movies && fetchMoreResult.movies.edges) || [])],
                pageInfo: fetchMoreResult.movies && fetchMoreResult.movies.pageInfo,
              }
            });
          }
        })}
      >
        <div className="search_list">
          {data.movies.edges.map(({ node: movie }: SearchMovies_movies_edges) => (
            movie &&
            <div key={movie.imdbID}>
              <img src={String(movie.Poster)} alt={`Poster ${movie.Title}`} />
              <div className="details">
                <h4>{movie.Title} <span className="release_date">({movie.Year})</span></h4>
                <div className="actions">
                  <Link to={`/profile/${movie.imdbID}`} style={{ marginRight: "1em" }}><button>Plus d'infos</button></Link>
                  <a href={`https://www.imdb.com/title/${movie.imdbID}/?ref_=tt_pg`} target="_blank" rel="noreferrer"><button>IMDb</button></a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </>
  );
}
