import { ChangeEvent, useEffect, useState } from "react";

import { List } from "./component/list";

export function HomeComponent() {
  const [searchInput, setSearchInput] = useState("star");
  const [callReady, setCallReady] = useState(false);

  useEffect(() => {
    setTimeout(() => setCallReady(Boolean(searchInput && searchInput.length >= 3)), 500);
  }, [searchInput, setCallReady]);

  return (
    <>
      <input
        type="text"
        value={searchInput}
        style={{
          fontSize: "16px",
          height: "36px",
          width: "420px",
          padding: "5px 15px",
          margin: "20px 35px"
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setCallReady(false);
          setSearchInput(String(e.target.value));
        }}
        placeholder="Rechercher un film ..."
      />
      {callReady && <List searchInput={searchInput} />}
    </>
  );
}
